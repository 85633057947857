import { render, staticRenderFns } from "./SelectMatchedSkills.vue?vue&type=template&id=2d9e3ba8&"
import script from "./SelectMatchedSkills.vue?vue&type=script&lang=ts&"
export * from "./SelectMatchedSkills.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports