









import Vue from 'vue'
import {
    IUuid,
    IJob,
    IJobApplication,
    IJobReferralDetails,
    IStateDashboardJobApplications,
} from '@/types'
import { REPOSITORY_JOB_APPLICATIONS, REPOSITORY_JOB_REFFERALS } from '@/repositiories'
import { BaseSpinner } from '@/components'
import { ApplicationList } from './components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_JOB_APPLICATIONS, STORE_MODULE } from '@/enums'

const { mapState, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_JOB_APPLICATIONS
)

export default Vue.extend({
    name: 'JobApplications',

    components: { BaseSpinner, ApplicationList },

    props: {
        job: {
            type: Object as () => IJob,
            required: true,
        },
    },

    computed: {
        ...(mapState(['applications']) as MapStateToComputed<IStateDashboardJobApplications>),
    },

    async created() {
        try {
            await this.fetchAndSetJobApplications(this.job.id)
        } catch (error) {
            alert(error.message)
        }
    },

    methods: {
        ...mapActions({
            fetchAndSetJobApplications:
                ACTION_DASHBOARD_JOB_APPLICATIONS.FETCH_AND_SET_APPLICATIONS,
        }),
    },
})
