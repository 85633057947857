












import Vue from 'vue'
import { IFilters } from './types'
import { Filters, ListItemApplication } from './components'
import { AVAILABILITY_TYPE, SOURCE_TYPE, MATCHED_SKILLS } from './constants'
import { IJobApplication } from '@/types'

export default Vue.extend({
    name: 'ApplicationList',

    components: { Filters, ListItemApplication },

    props: {
        applications: {
            type: Array as () => IJobApplication[],
            required: true,
        },
    },

    data: () => ({
        filters: {
            query: '',
            noticePeriodId: null,
            state: null,
            availability: AVAILABILITY_TYPE.ALL,
            matchedSkill: MATCHED_SKILLS.ALL,
            source: SOURCE_TYPE.ALL,
        } as IFilters,
    }),

    computed: {
        applicationsOrdered(): IJobApplication[] {
            return [...this.applications].sort(
                (a, b) => b.timestampCreated - a.timestampCreated
            )
        },

        applicationsOrderedFiltered(): IJobApplication[] {
            let result = this.applicationsOrdered

            result = this.filterApplicationsByQuery(result, this.filters.query)
            result = this.filterApplicationsByState(result, this.filters.state)
            result = this.filterApplicationsByNoticePeriod(result, this.filters.noticePeriodId)
            result = this.filterApplicationsByAvailability(result, this.filters.availability)
            result = this.filterApplicationsByMatchedSkills(result, this.filters.matchedSkill)
            result = this.filterApplicationsBySource(result, this.filters.source)

            return result
        },
    },

    methods: {
        filterApplicationsByQuery(
            applications: IJobApplication[],
            query: IFilters['query']
        ): IJobApplication[] {
            if (!query) return applications

            return applications.filter((application) => {
                const { firstName, lastName, referral } = application
                const parts = [firstName, lastName]

                if (referral) parts.push(referral.referrerFirstName, referral.referrerLastName)

                return parts.join(' ').toLowerCase().includes(query.toLowerCase())
            })
        },

        filterApplicationsByState(
            applications: IJobApplication[],
            state: IFilters['state']
        ): IJobApplication[] {
            if (!state) return applications

            return applications.filter(({ state: { id } }) => id === state.id)
        },

        filterApplicationsByNoticePeriod(
            applications: IJobApplication[],
            noticePeriodId: IFilters['noticePeriodId']
        ): IJobApplication[] {
            if (!noticePeriodId) return applications

            return applications.filter(({ noticePeriod }) => noticePeriod.id === noticePeriodId)
        },

        filterApplicationsByAvailability(
            applications: IJobApplication[],
            availability: IFilters['availability']
        ): IJobApplication[] {
            switch (availability) {
                case AVAILABILITY_TYPE.FULL_TIME:
                    return applications.filter(
                        (application) => !application.isPartTimeAvailable
                    )
                case AVAILABILITY_TYPE.PART_TIME:
                    return applications.filter((application) => application.isPartTimeAvailable)
                default:
                    return applications
            }
        },

        filterApplicationsByMatchedSkills(
            applications: IJobApplication[],
            matchedSkill: IFilters['matchedSkill']
        ): IJobApplication[] {
            switch (matchedSkill) {
                case MATCHED_SKILLS.ALL:
                    return applications
                case MATCHED_SKILLS.HAS_ALL_ESSENTIAL_SKILLS:
                    return applications.filter((application) => application.areAllSkillsMatched)
                case MATCHED_SKILLS.PARTIAL_ESSENTIAL_SKILLS:
                    return applications.filter(
                        (application) => !application.areAllSkillsMatched
                    )
                default:
                    return applications
            }
        },

        filterApplicationsBySource(
            applications: IJobApplication[],
            source: IFilters['source']
        ): IJobApplication[] {
            switch (source) {
                case SOURCE_TYPE.DIRECT:
                    return applications.filter((application) => !application.referral)
                case SOURCE_TYPE.REFERRAL:
                    return applications.filter((application) => !!application.referral)
                default:
                    return applications
            }
        },
    },
})
