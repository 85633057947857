import { render, staticRenderFns } from "./TraitItem.vue?vue&type=template&id=45ea05cd&scoped=true&"
import script from "./TraitItem.vue?vue&type=script&lang=ts&"
export * from "./TraitItem.vue?vue&type=script&lang=ts&"
import style0 from "./TraitItem.vue?vue&type=style&index=0&id=45ea05cd&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ea05cd",
  null
  
)

export default component.exports