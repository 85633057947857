










import Vue from 'vue'
import { BaseFieldSelect } from '@/components'
import { IFilters } from '../../../../types'
import { AVAILABILITY_TYPE } from '../../../../constants'

interface IAvailability {
    text: string
    value: AVAILABILITY_TYPE
}

export default Vue.extend({
    name: 'SelectAvailability',

    components: { BaseFieldSelect },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    computed: {
        availability: {
            get(): IFilters['availability'] {
                return this.filters.availability
            },
            set(availability: IFilters['availability']) {
                this.$emit('update:filters', { ...this.filters, availability })
            },
        },

        availabilities(): IAvailability[] {
            return [
                {
                    text: 'All availabilities',
                    value: AVAILABILITY_TYPE.ALL,
                },
                {
                    text: 'Only full time',
                    value: AVAILABILITY_TYPE.FULL_TIME,
                },
                {
                    text: 'Only part time',
                    value: AVAILABILITY_TYPE.PART_TIME,
                },
            ]
        },
    },
})
