
















































import Vue from 'vue'
import { REPOSITORY_JOB_APPLICATIONS } from '@/repositiories'
import { IJobApplication, IJobApplicationDetails } from '@/types'
import { BaseSpinner, BaseBtnContainer } from '@/components'
import { DetailsItem, AttachmentLink, BtnAction } from './components'
import { JOB_APPLICATION_STATE_ID } from '@/enums'

export default Vue.extend({
    name: 'ApplicationDetails',

    components: { BaseSpinner, BaseBtnContainer, DetailsItem, AttachmentLink, BtnAction },

    props: {
        application: {
            type: Object as () => IJobApplication,
            required: true,
        },
    },

    data: () => ({
        details: undefined as undefined | IJobApplicationDetails,
    }),

    computed: {
        canDownloadAttachments(): boolean {
            return this.application.state.id !== JOB_APPLICATION_STATE_ID.NEW
        },
    },

    async created() {
        try {
            this.details = await REPOSITORY_JOB_APPLICATIONS.fetchJobApplicationDetails(
                this.application.jobId,
                this.application.id
            )
        } catch (error) {
            alert(error.message)
        }
    },
})
