












import { IJobReferralTrait } from '@/types'
import Vue from 'vue'

export default Vue.extend({
    name: 'TraitItem',

    props: {
        trait: {
            type: Object as () => IJobReferralTrait,
            required: true,
        },
    },

    computed: {
        variant(): string {
            switch (true) {
                case this.trait.value < 30:
                    return 'danger'
                case this.trait.value < 60:
                    return 'warning'
                default:
                    return 'success'
            }
        },
    },
})
