




import Vue from 'vue'
import debounce from 'lodash/debounce'
import { BaseFieldText } from '@/components'
import { IFilters } from '../../../../types'

const DEBOUNCE_TIME_MS = 400

export default Vue.extend({
    name: 'FieldQuery',

    components: { BaseFieldText },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    computed: {
        query: {
            get(): IFilters['query'] {
                return this.filters.query
            },
            set(query: IFilters['query']) {
                this.updateQueryDebounced(query)
            },
        },
    },

    methods: {
        updateQueryDebounced: debounce(function (query: IFilters['query']) {
            // @ts-ignore
            this.$emit('update:filters', { ...this.filters, query })
        }, DEBOUNCE_TIME_MS),
    },
})
