











import Vue from 'vue'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { IAttachment } from '@/types'

export default Vue.extend({
    name: 'AttachmentLink',

    props: {
        attachment: {
            type: Object as () => IAttachment,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({ faPaperclip }),

    methods: {
        async downloadAttachment() {
            if (this.disabled) return

            try {
                const directUrl = await this.attachment.getFileLink()

                window.open(directUrl)
            } catch (e) {}
        },
    },
})
