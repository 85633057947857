import { render, staticRenderFns } from "./ApplicationReferral.vue?vue&type=template&id=2d2e9e8e&scoped=true&"
import script from "./ApplicationReferral.vue?vue&type=script&lang=ts&"
export * from "./ApplicationReferral.vue?vue&type=script&lang=ts&"
import style0 from "./ApplicationReferral.vue?vue&type=style&index=0&id=2d2e9e8e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2e9e8e",
  null
  
)

export default component.exports