<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <h2>{{ job.title }}</h2>

                <div>
                    <BButton
                        class="mr-2"
                        variant="outline-secondary"
                        title="Edit job"
                        size="sm"
                        @click="editJob"
                    >
                        <FontAwesomeIcon :icon="faEdit" />
                    </BButton>
                    <BButton
                        class="mr-2"
                        title="Clone job"
                        variant="outline-secondary"
                        size="sm"
                    >
                        <FontAwesomeIcon :icon="faCopy" />
                    </BButton>
                    <BButton
                        variant="outline-danger"
                        size="sm"
                        @click="showModalDeactivateJob = true"
                    >
                        Deactivate job
                    </BButton>
                </div>
            </div>
            <small class="text-uppercase">department name</small>
            <hr />

            <BaseModal
                v-model="showModalDeactivateJob"
                title="Confirmation"
                hide-footer="hide-footer"
            >
                <p>
                    Do you confirm to deactivate job <b>{{ job.title }}</b
                    >?
                </p>
                <div class="row">
                    <div class="col-6">
                        <BButton variant="danger" @click="deactivateJob">
                            Yes, proceed
                        </BButton>
                        <BButton
                            class="ml-2"
                            variant="outline-secondary"
                            @click="showModalDeactivateJob = false"
                        >
                            Cancel
                        </BButton>
                    </div>
                </div>
            </BaseModal>

            <JobApplications v-if="isJobLoaded" v-bind="{ job }" />
        </div>
    </div>
</template>
<script>
import { BaseModal } from '@/components'
import { faEdit, faCopy } from '@fortawesome/free-solid-svg-icons'
import { JobApplications } from './components'
import { ROUTE } from '@/enums'

export default {
    name: 'ManageJob',

    components: { BaseModal, JobApplications },

    created() {
        if (!this.isJobLoaded)
            this.$route.params.job ? this.setJobFromParentParams() : this.fetchJob()
    },

    computed: {
        isJobLoaded: (vm) =>
            Object.entries(vm.job).length !== 0 && vm.job.constructor === Object,
    },

    data: () => ({
        job: {},
        showModalDeactivateJob: false,
        faEdit,
        faCopy,
    }),

    methods: {
        setJobFromParentParams() {
            this.job = this.$route.params.job
        },

        fetchJob() {
            this.$store.state.backend
                .get(`/getjobs/${this.$route.params.id}`)
                .then((res) => (this.job = res.data))
                .catch((err) => alert(err))
        },

        editJob() {
            this.$router.push({ name: ROUTE.DASHBOARD_JOB_EDIT, params: { job: this.job } })
        },

        deactivateJob(flag) {
            this.$store.state.backend
                .post(`/getjobs/delete`, { id: this.$route.params.id })
                .then((res) => {
                    this.showModalDeactivateJob = false

                    if (res.status === 'error') {
                        throw new Error({ message: 'There is an error in deactivating job' })
                    }
                    this.$router.push({
                        name: ROUTE.DASHBOARD_JOBS,
                        query: { removed_id: this.$route.params.id },
                    })
                })
                .catch((e) => alert(e.message))
        },
    },
}
</script>
