










import Vue from 'vue'
import { BaseFieldSelect } from '@/components'
import { IFilters } from '../../../../types'
import { MATCHED_SKILLS } from '../../../../constants'

interface IMatchedSkills {
    text: string
    value: MATCHED_SKILLS
}

export default Vue.extend({
    name: 'SelectMatchedSkills',

    components: { BaseFieldSelect },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    computed: {
        matchedSkill: {
            get(): IFilters['matchedSkill'] {
                return this.filters.matchedSkill
            },
            set(matchedSkill: IFilters['matchedSkill']) {
                this.$emit('update:filters', { ...this.filters, matchedSkill })
            },
        },

        matchedSkills(): IMatchedSkills[] {
            return [
                {
                    text: 'All applications',
                    value: MATCHED_SKILLS.ALL,
                },
                {
                    text: 'Has all essential skills',
                    value: MATCHED_SKILLS.HAS_ALL_ESSENTIAL_SKILLS,
                },
                {
                    text: 'Partial essential skills',
                    value: MATCHED_SKILLS.PARTIAL_ESSENTIAL_SKILLS,
                },
            ]
        },
    },
})
