






























import Vue from 'vue'
import { IFilters } from '../../types'
import {
    FieldQuery,
    SelectState,
    SelectAvailability,
    SelectNoticePeriod,
    SelectSource,
    SelectMatchedSkills,
} from './components'

export default Vue.extend({
    name: 'Filters',

    components: {
        FieldQuery,
        SelectState,
        SelectAvailability,
        SelectNoticePeriod,
        SelectSource,
        SelectMatchedSkills,
    },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    computed: {
        filtersInternal: {
            get(): IFilters {
                return this.filters
            },
            set(filters: IFilters) {
                this.$emit('update:filters', filters)
            },
        },
    },
})
