




import Vue from 'vue'
import { BaseFieldSelect } from '@/components'
import { IFilters } from '../../../../types'
import { SOURCE_TYPE } from '../../../../constants'

interface ISource {
    text: string
    value: SOURCE_TYPE
}

export default Vue.extend({
    name: 'SelectSource',

    components: { BaseFieldSelect },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    computed: {
        source: {
            get(): IFilters['source'] {
                return this.filters.source
            },
            set(source: IFilters['source']) {
                this.$emit('update:filters', { ...this.filters, source })
            },
        },

        sources(): ISource[] {
            return [
                {
                    text: 'All sources',
                    value: SOURCE_TYPE.ALL,
                },
                {
                    text: 'Only direct',
                    value: SOURCE_TYPE.DIRECT,
                },
                {
                    text: 'Only referred',
                    value: SOURCE_TYPE.REFERRAL,
                },
            ]
        },
    },
})
