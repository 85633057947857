











import Vue from 'vue'
import { faCheck, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import {
    IJobApplication,
    IJobApplicationAction,
    IJobApplicationActionCallPayload,
} from '@/types'
import { BaseBtn } from '@/components'
import {
    ACTION_DASHBOARD_JOB_APPLICATIONS,
    JOB_APPLICATION_ACTION_ID,
    STORE_MODULE,
} from '@/enums'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_JOB_APPLICATIONS)

export default Vue.extend({
    name: 'BtnAction',

    components: { BaseBtn },

    props: {
        action: {
            type: Object as () => IJobApplicationAction,
            required: true,
        },
        application: {
            type: Object as () => IJobApplication,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
    }),

    computed: {
        btnVariant(): string {
            return this.action.id === JOB_APPLICATION_ACTION_ID.REJECT
                ? 'outline-danger'
                : 'success'
        },

        btnIcon(): IconDefinition {
            return this.action.id === JOB_APPLICATION_ACTION_ID.REJECT ? faTimes : faCheck
        },
    },

    methods: {
        ...mapActions({
            callApplicationAction: ACTION_DASHBOARD_JOB_APPLICATIONS.CALL_APPLICATION_ACTION,
        }),

        async callAction() {
            this.isRequestInProgress = true

            try {
                const payload: IJobApplicationActionCallPayload = {
                    jobId: this.application.jobId,
                    jobApplicationId: this.application.id,
                    jobApplicationActionId: this.action.id,
                }

                await this.callApplicationAction(payload)
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
