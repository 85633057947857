










import Vue from 'vue'
import { BaseFieldSelect } from '@/components'
import { IFilters } from '../../../../types'
import { IUuid, IJobNoticePeriod } from '@/types'
import { REPOSITORY_JOB_NOTICE_PERIODS } from '@/repositiories'

interface INoticePeriod {
    text: string
    value: IFilters['noticePeriodId']
}

export default Vue.extend({
    name: 'SelectNoticePeriod',

    components: { BaseFieldSelect },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    data: () => ({
        noticePeriods: [] as IJobNoticePeriod[],
    }),

    computed: {
        noticePeriodId: {
            get(): IFilters['noticePeriodId'] {
                return this.filters.noticePeriodId
            },
            set(noticePeriodId: IFilters['noticePeriodId']) {
                this.$emit('update:filters', { ...this.filters, noticePeriodId })
            },
        },

        noticePeriodsWithDefault(): INoticePeriod[] {
            return [
                {
                    text: 'All notice periods',
                    value: null,
                },
                ...this.noticePeriods.map(({ id, name }) => ({ text: name, value: id })),
            ]
        },
    },

    async created() {
        try {
            this.noticePeriods = await REPOSITORY_JOB_NOTICE_PERIODS.fetchNoticePeriods()
        } catch (error) {}
    },
})
