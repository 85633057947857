



















import Vue from 'vue'
import { IJobReferralDetails } from '@/types'
import { getFormattedFullName } from '@/utils'
import { TraitItem } from './components'

export default Vue.extend({
    name: 'ApplicationReferral',

    components: { TraitItem },

    props: {
        referral: {
            type: Object as () => IJobReferralDetails,
            required: true,
        },
    },

    computed: {
        referrerFullName(): string {
            const { referrerFirstName: firstName, referrerLastName: lastName } = this.referral

            return getFormattedFullName({ firstName, lastName })
        },
    },
})
