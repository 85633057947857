




























import Vue from 'vue'
import { IJobApplication } from '@/types'
import { getFormattedDateTime } from '@/utils'
import { faClock, faCalendarCheck, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faEye, faTasks, faRoute } from '@fortawesome/free-solid-svg-icons'
import { BaseListItemProps, BaseListItemProp } from '@/components'

export default Vue.extend({
    name: 'ApplicationProps',

    components: { BaseListItemProps, BaseListItemProp },

    props: {
        application: {
            type: Object as () => IJobApplication,
            required: true,
        },
    },

    data: () => ({ faClock, faCalendarCheck, faPaperPlane, faEye, faTasks, faRoute }),

    computed: {
        timeAvailabilityFormatted(): string {
            return this.application.isPartTimeAvailable ? 'Part time' : 'Full time'
        },

        hasAllSkillsFormatted(): string {
            return this.application.areAllSkillsMatched
                ? 'Has all essential skills'
                : 'Partial essential skills'
        },

        noticePeriodFormatted(): string {
            return `Notice period: ${this.application.noticePeriod.name}`
        },

        sourceFormatted(): string {
            return `Source: ${this.application.referral ? 'Referral' : 'Direct'}`
        },

        timestampCraetedFormatted(): string {
            return getFormattedDateTime(this.application.timestampCreated)
        },
    },
})
