










import Vue from 'vue'
import { BaseFieldSelect } from '@/components'
import { IFilters } from '../../../../types'
import { IUuid, IJobApplicationState } from '@/types'
import { REPOSITORY_JOB_APPLICATIONS } from '@/repositiories'

interface IJobApplicationStateItem {
    text: string
    value: IFilters['state'] | null
}

export default Vue.extend({
    name: 'SelectState',

    components: { BaseFieldSelect },

    model: {
        prop: 'filters',
        event: 'update:filters',
    },

    props: {
        filters: {
            type: Object as () => IFilters,
            required: true,
        },
    },

    data: () => ({
        states: [] as IJobApplicationState[],
    }),

    computed: {
        state: {
            get(): IFilters['state'] {
                return this.filters.state
            },
            set(state: IFilters['state']) {
                this.$emit('update:filters', { ...this.filters, state })
            },
        },

        statesWithDefault(): IJobApplicationStateItem[] {
            return [
                {
                    text: 'All states',
                    value: null,
                },
                ...this.states.map((state) => ({ text: state.name, value: state })),
            ]
        },
    },

    async created() {
        try {
            this.states = await REPOSITORY_JOB_APPLICATIONS.fetchJobApplicationStates()
        } catch (error) {}
    },
})
