



























import Vue from 'vue'
import { IJobApplication, IJobReferralDetails } from '@/types'
import { getFormattedFullName } from '@/utils'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseListItem } from '@/components'
import { ApplicationDetails, ApplicationProps, ApplicationReferral } from './components'

export default Vue.extend({
    name: 'ListItemApplication',

    components: { BaseListItem, ApplicationDetails, ApplicationProps, ApplicationReferral },

    props: {
        application: {
            type: Object as () => IJobApplication,
            required: true,
        },
    },

    data: () => ({
        isExpanded: false,
        faPaperPlane,
    }),

    computed: {
        fullName(): string {
            return getFormattedFullName(this.application)
        },

        toggleIcon(): IconDefinition {
            return this.isExpanded ? faChevronUp : faChevronDown
        },
    },
})
