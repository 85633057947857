








import Vue from 'vue'

export default Vue.extend({
    name: 'DetailsItem',

    props: {
        label: {
            type: String,
            required: true,
        },
    },
})
